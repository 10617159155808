<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="640"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 6 }, xs: { span: 8 } }"
      :wrapper-col="{ sm: { span: 18 }, xs: { span: 16 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 租赁点名称 -->
          <a-form-item label="模板名称:">
            <div class="ud-text-secondary">
              {{ data.name }}
            </div>
          </a-form-item>
          <!-- 租赁点类型 -->
          <a-form-item label="支付方式:">
            <div class="ud-text-secondary">
              {{ data.method === 'wechat' ? '微信支付' : '支付宝支付' }}
            </div>
          </a-form-item>
          <a-form-item label="备注:">
            <div class="ud-text-secondary">
              {{ data.remarks }}
            </div>
          </a-form-item>
          <!-- 微信支付 -->
          <div v-if="data.method === 'wechat'">
            <a-form-item label="微信支付接口版本:">
              <div class="ud-text-secondary">
                {{ configObj.wechat.version }}
              </div>
            </a-form-item>
            <a-form-item label="微信商户号类型:">
              <div class="ud-text-secondary">
                {{
                  configObj.wechat.mchType === 'normal' ? '普通商户' : '子商户'
                }}
              </div>
            </a-form-item>
            <!-- 普通商户 -->
            <div v-if="configObj.wechat.mchType === 'normal'">
              <a-form-item label="应用ID:">
                <div class="ud-text-secondary">
                  {{ configObj.wechat.normal.appId }}
                </div>
              </a-form-item>
              <a-form-item label="微信商户号:">
                <div class="ud-text-secondary">
                  {{ configObj.wechat.normal.mchId }}
                </div>
              </a-form-item>
              <a-form-item label="支付密钥:">
                <div class="ud-text-secondary">
                  {{ configObj.wechat.normal.apiKey }}
                </div>
              </a-form-item>
              <a-form-item label="证书文件(CERT):">
                <div class="ud-text-secondary">
                  {{ configObj.wechat.normal.apiclientCert }}
                </div>
              </a-form-item>
              <a-form-item label="证书文件(KEY):">
                <div class="ud-text-secondary">
                  {{ configObj.wechat.normal.apiclientKey }}
                </div>
              </a-form-item>
            </div>
            <!-- 子商户 -->
            <div v-else>
              <a-form-item
                label="服务商应用ID(AppID):"
                name="configObj.wechat.provider.spAppId"
              >
                <div class="ud-text-secondary">
                  {{ configObj.wechat.provider.spAppId }}
                </div>
              </a-form-item>
              <a-form-item
                label="服务商户号(MchId):"
                name="configObj.wechat.provider.spMchId"
              >
                <div class="ud-text-secondary">
                  {{ configObj.wechat.provider.spMchId }}
                </div>
              </a-form-item>

              <a-form-item
                label="服务商密钥(APIKEY):"
                name="configObj.wechat.provider.spApiKey"
              >
                <div class="ud-text-secondary">
                  {{ configObj.wechat.provider.spApiKey }}
                </div>
              </a-form-item>

              <a-form-item
                label="子商户应用ID(APPID):"
                name="configObj.wechat.provider.subAppId"
              >
                <div class="ud-text-secondary">
                  {{ configObj.wechat.provider.subAppId }}
                </div>
              </a-form-item>

              <a-form-item
                label="子商户号(MchId):"
                name="configObj.wechat.provider.subMchId"
              >
                <div class="ud-text-secondary">
                  {{ configObj.wechat.provider.subMchId }}
                </div>
              </a-form-item>

              <a-form-item
                label="服务商证书文件(CERT):"
                name="configObj.wechat.provider.spApiclientCert"
              >
                <div class="ud-text-secondary">
                  {{ configObj.wechat.provider.spApiclientCert }}
                </div>
              </a-form-item>

              <a-form-item
                label="服务商证书文件(KEY):"
                name="configObj.wechat.provider.spApiclientKey"
              >
                <div class="ud-text-secondary">
                  {{ configObj.wechat.provider.spApiclientKey }}
                </div>
              </a-form-item>
            </div>
          </div>
          <div v-else>
            <a-form-item
              label="支付宝应用(AppID):"
              name="configObj.alipay.appId"
            >
              <div class="ud-text-secondary">
                {{ configObj.alipay.appId }}
              </div>
            </a-form-item>

            <a-form-item
              label="签名算法(signType):"
              name="configObj.alipay.signType"
            >
              <div class="ud-text-secondary">
                {{ configObj.alipay.signType }}
              </div>
            </a-form-item>
            <a-form-item
              label="加签模式(signMode):"
              name="configObj.alipay.signMode"
            >
              <div class="ud-text-secondary">
                {{ configObj.alipay.signMode }}
              </div>
            </a-form-item>
            <div v-if="configObj.alipay.signMode === 10">
              <a-form-item
                label="应用公钥证书:"
                name="configObj.alipay.appCertPublicKey"
              >
                <div class="ud-text-secondary">
                  {{ configObj.alipay.appCertPublicKey }}
                </div>
              </a-form-item>

              <a-form-item
                label="支付宝公钥证书:"
                name="configObj.alipay.alipayCertPublicKey"
              >
                <div class="ud-text-secondary">
                  {{ configObj.alipay.alipayCertPublicKey }}
                </div>
              </a-form-item>

              <a-form-item
                label="支付宝根证书:"
                name="configObj.alipay.alipayRootCert"
              >
                <div class="ud-text-secondary">
                  {{ configObj.alipay.alipayRootCert }}
                </div>
              </a-form-item>
            </div>
            <a-form-item
              v-if="configObj.alipay.signMode === 20"
              style="margin-bottom: 0"
              label="支付宝公钥(alipayPublicKey):"
              name="configObj.alipay.alipayPublicKey"
            >
              <div class="ud-text-secondary">
                {{ configObj.alipay.alipayPublicKey }}
              </div>
            </a-form-item>

            <a-form-item
              label="应用私钥(privateKey):"
              name="configObj.alipay.merchantPrivateKey"
            >
              <div class="ud-text-secondary">
                {{ configObj.alipay.merchantPrivateKey }}
              </div>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'templateDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {
      configObj: null
    }
  },
  watch: {
    data() {
      if (this.data.config) {
        this.configObj = JSON.parse(this.data.config)
      }
    }
  },
  methods: {
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.photo {
  width: 200px;
  height: 100px;
  margin: 20px 0;
}
</style>
